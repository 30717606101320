import React from 'react'
import Layout from '../components/Layout'
import CookieConsent from 'react-cookie-consent'
import { SWRConfig } from 'swr'

import 'moment/locale/fr'
import 'leaflet/dist/leaflet.css'
import 'react-image-lightbox/style.css'
import '../scss/style.default.scss'
import 'react-phone-input-2/lib/style.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'simplebar-react/dist/simplebar.min.css'

import { UserProvider } from '../contexts/UserContext'
import { ScriptProvider } from '../contexts/ScriptContext'
import { HeaderCollapsedProvider } from '../contexts/HeaderCollapsedContext'
import { useLoadScript } from '@react-google-maps/api'
import Script from 'next/script'
import { useRouter } from 'next/router'
import * as fbq from '../lib/fpixel'
import { Toaster } from 'react-hot-toast'
import { Button } from 'reactstrap'

import { fr } from 'yup-locales'
import { setLocale } from 'yup'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
import { CrmApi } from 'typescript-axios'
import { translate } from '../translations/utils'
import { getAxiosParams } from '../utils/client'
import localStorageProvider from '../utils/localStorageProvider'

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

setLocale(fr)

const CRM_SERVICE = new CrmApi(getAxiosParams())

function getLoggedUser() {
  if (typeof window === 'undefined') return null
  try {
    const loggedUser = JSON.parse(localStorage.getItem('loggedUser'))
    return loggedUser
  } catch (e) {
    localStorage.removeItem('loggedUser')
    return null
  }
}

function getGuruCode() {
  if (typeof window === 'undefined') return null
  try {
    return localStorage.getItem('guruCode')
  } catch (e) {
    localStorage.removeItem('guruCode')
    return null
  }
}

function getCalendarView() {
  if (typeof window === 'undefined') return null
  try {
    const calendarView = JSON.parse(localStorage.getItem('calendarView'))
    return calendarView
  } catch (e) {
    localStorage.removeItem('calendarView')
    return null
  }
}

function getCalendarBound(key) {
  if (typeof window === 'undefined') return null
  try {
    let bound = localStorage.getItem(key)
    bound = bound ? new Date(Date.parse(bound)) : undefined
    return bound
  } catch (e) {
    localStorage.removeItem(key)
    return null
  }
}

const LIBRARIES = ['places']

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps, err }) => {
  const [guruCode, setGuruCode] = React.useState(getGuruCode())
  const [referralCode, setReferralCode] = React.useState()
  const [placesLoaded, setPlacesLoaded] = React.useState(false)
  const [loggedUser, setLoggedUser] = React.useState(getLoggedUser())
  const [remainingTickets, setRemainingTickets] = React.useState(null)
  const [calendarView, setCalendarView] = React.useState(
    getCalendarView() ?? 'work_week'
  )
  const [calendarMin, setCalendarMin] = React.useState(
    getCalendarBound('calendarMin')
  )
  const [calendarMax, setCalendarMax] = React.useState(
    getCalendarBound('calendarMax')
  )
  const [isGoogleCalendarSignedIn, setIsGoogleCalendarSignedIn] =
    React.useState(null)
  const [isInstaSignedIn, setIsInstaSignedIn] = React.useState(null)
  const [headerCollapsed, setHeaderCollapsed] = React.useState(false)
  const [isHeaderDropdownOpen, setIsHeaderDropdownOpen] = React.useState(false)
  const [navBarColor, setNavBarColor] = React.useState(
    pageProps.nav?.color ? pageProps.nav.color : 'white'
  )
  const [navBarLight, setNavBarLight] = React.useState(pageProps.nav?.light)
  const [navBarDark, setNavBarDark] = React.useState(pageProps.nav?.dark)

  const t = (key) => translate(key, pageProps.locale, pageProps.defaultLocale)

  const router = useRouter()

  const cookieConsentRef = React.useRef()

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  React.useEffect(() => {
    // on page change we need to discard what was in the context
    if (pageProps.nav) {
      setNavBarColor(pageProps.nav?.color ? pageProps.nav.color : 'white')
      setNavBarLight(pageProps.nav?.light)
      setNavBarDark(pageProps.nav?.dark)
    }
  }, [pageProps.nav])

  const setGuruCodeAndReplace = (key) => {
    setGuruCode(router.query[key])
    delete router.query[key]
    router.replace(router)
  }

  // function for checking whether visitor has consented before
  function checkConsented() {
    let decodedCookie = decodeURIComponent(document.cookie)
    decodedCookie = decodedCookie.split(';')
    decodedCookie = decodedCookie.find(
      (cookie) => cookie.substring(0, 13) === 'CookieConsent'
    )
    if (!decodedCookie) {
      return false
    }
    if (decodedCookie.substring(14, 18) === 'true') {
      return true
    }
    return false
  }

  // for subsequent page visits
  React.useEffect(() => {
    if (checkConsented()) {
      // window.gtag("consent", "update", {
      //   ad_storage: "granted",
      //   analytics_storage: "granted",
      // });
      window.fbq('consent', 'grant')
    }
  }, [])

  React.useEffect(() => {
    if (router.isReady) {
      if (router.query.code) {
        if (router.query.code.length == 8) {
          // FIXME: hotfix, SSO also uses this code qry string parameter...
          setGuruCodeAndReplace('code')
        }
      }
      if (router.query.referral) {
        setGuruCodeAndReplace('referral')
      }
    }
  }, [router.isReady])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCPF23ceF8bwHOtGIX1eo8lmzpeIy9A_aQ',
    libraries: LIBRARIES
  })

  if (isLoaded && !placesLoaded) {
    setPlacesLoaded(true)
  }

  // override the logged fields, not object in case the new user has less attributes
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Global Site Code Pixel - Facebook Pixel */}
        <Script
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('consent', 'revoke');
          fbq('init', ${fbq.FB_PIXEL_ID});
        `
          }}
        />
        <HeaderCollapsedProvider
          collapsed={headerCollapsed}
          setCollapsed={setHeaderCollapsed}
          isDropdownOpen={isHeaderDropdownOpen}
          setIsDropdownOpen={setIsHeaderDropdownOpen}
          navBarColor={navBarColor}
          setNavBarColor={setNavBarColor}
          navBarLight={navBarLight}
          setNavBarLight={setNavBarLight}
          navBarDark={navBarDark}
          setNavBarDark={setNavBarDark}
        >
          <ScriptProvider
            placesLoaded={placesLoaded}
            setPlacesLoaded={setPlacesLoaded}
          >
            <UserProvider
              remainingTickets={remainingTickets}
              setRemainingTickets={setRemainingTickets}
              calendarView={calendarView}
              setCalendarView={setCalendarView}
              calendarMin={calendarMin}
              setCalendarMin={setCalendarMin}
              calendarMax={calendarMax}
              setCalendarMax={setCalendarMax}
              isGoogleCalendarSignedIn={isGoogleCalendarSignedIn}
              setIsGoogleCalendarSignedIn={setIsGoogleCalendarSignedIn}
              isInstaSignedIn={isInstaSignedIn}
              setIsInstaSignedIn={setIsInstaSignedIn}
              guruCode={guruCode}
              setGuruCode={setGuruCode}
              loggedUser={loggedUser}
              setLoggedUser={(v) => {
                if (v === null) {
                  setGuruCode(null)
                }
                setLoggedUser(v === null ? null : { ...loggedUser, ...v })
              }}
            >
              <Layout {...pageProps}>
                <Component {...pageProps} err={err} />
              </Layout>
              <Toaster
                position='bottom-center'
                reverseOrder={false}
                toastOptions={{
                  duration: 3000
                }}
              />
            </UserProvider>
          </ScriptProvider>
        </HeaderCollapsedProvider>
      </LocalizationProvider>
      {!pageProps.hideCookies && (
        <CookieConsent
          style={{ zIndex: 1030 }}
          onDecline={() => {
            console.debug('cookiesDeclined')
          }}
          onAccept={() => {
            console.debug('cookiesAccepted')
            // window.gtag("consent", "update", {
            //   ad_storage: "granted",
            //   analytics_storage: "granted",
            // });
            window.fbq('consent', 'grant')
          }}
          ref={cookieConsentRef}
          containerClasses='bg-gray-100'
          contentClasses='text-muted'
          buttonStyle={{ display: 'none' }}
        >
          <div className='d-lg-flex justify-content-between'>
            <div className='flex-grow-1'>
              {t('cookies_consent')}
              <a className='ml-2' href='/terms#cookies'>
                <small>{t('know_more')}</small>
              </a>
            </div>
            <div className='d-flex justify-content-end mt-2 mt-lg-0'>
              <Button
                onClick={() => cookieConsentRef.current.decline()}
                color='link'
                size='sm'
              >
                {t('i_decline')}
              </Button>
              <Button
                onClick={() => cookieConsentRef.current.accept()}
                color='primary'
                className='ml-2'
                size='sm'
              >
                {t('i_accept')}
              </Button>
            </div>
          </div>
        </CookieConsent>
      )}
    </>
  )
}

const _app = (props) => (
  <SWRConfig value={{ provider: localStorageProvider }}>
    <App {...props} />
  </SWRConfig>
)

export default _app
